

import Hero from "@/components/Hero.vue"
// import Footer from "@/components/Footer.vue"

export default {
    name: 'Home',
    components: {
      Hero,
    //   Footer
    },
    watch: {
        $route: {
            immediate: true,
            handler(to:any, from:any) {
                document.title = 'MasterGames | Home';
            }
        },
    }
};

